.Cart {
  color: #333;
  padding: 2rem;
}

.Cart ul {
  list-style-type: none;
}

.Cart li {
  padding: 1rem 0;
  margin: 0.5rem 0;
  width: 100%;
  border-bottom: 1px solid #efefef;
}

.Btn {
  color: #FFF;
  background-color: #6d7e87;
  font-size: large;
  text-transform: uppercase;
  padding: 1rem 2rem;
  border: 1px solid #6d7e87;
  cursor: pointer;
  z-index: 1;
  transition: all 0.5s;
}

.Btn:hover {
  text-decoration: none;
  color: #000;
  background-color: #FFF;
}
