.CartProduct {
  color: #222;
  align-items: center;
}

.MdText {
  color: #666;
  font-size: 2rem;
}

.Price {
  color: #222;
  font-size: 2.5rem;
  font-weight: 400;
}

.InputCounter {
  height: auto;
  width: 50%;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.CartProduct button {
  border: 1px solid #ced4da;
  border-radius: 0;
}

.CartProduct a,
.CartProduct a:hover {
  color: #999;
  text-decoration: none;
}

.CartProduct .Trash svg {
  font-size: 3rem;
  color: #999;
  cursor: pointer;
}
