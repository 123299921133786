.CategoryCard {
  padding: 1rem 0;
  text-align: center;
}

.CardContent {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.CardCover {
  height: 150px;
  width: 150px;
  display: inline-block;
  transition: transform 0.5s;
}

.CardCover img {
  display: inline-block;
  height: 100%;
}

.CardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CardOverlayCover {
  opacity: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}

.CardOverlayTitle {
  opacity: 0;
  color: #FFF;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s;
}

.CardOverlay::before {
  content: "";
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 50%;
  height: 50%;
  border-color: #FFF;
  border-style: solid;
  border-width: 1px 0 0 1px;
  transform: scale(0);
  transform-origin: top left;
  transition: transform 0.5s ease;
  z-index: 1;
}

.CardOverlay::after {
  content: "";
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 50%;
  height: 50%;
  border-color: #FFF;
  border-style: solid;
  border-width: 0 1px 1px 0;
  transform: scale(0);
  transform-origin: bottom right;
  transition: transform 0.5s ease;
  z-index: 1;
}

.CardContent:hover .CardCover {
  transform: scale(1.2);
}

.CardContent:hover .CardOverlayCover {
  opacity: 0.6;
}

.CardContent:hover .CardOverlayTitle {
  opacity: 1;
}

.CardContent:hover .CardOverlay::before {
  transform: scale(1);
}

.CardContent:hover .CardOverlay::after {
  transform: scale(1);
}
