.UserSidePanel {
  font-size: 2rem;
}

.Links {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  list-style-type: none;
}

.Links li{
  padding: 1rem;
}
