.BoxTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 2rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.BoxTitle a {
  color: #6d7e87;
}

.BoxTitle h4 {
  font-weight: 500;
}

.BoxContent {
  color: #777;
}

.BoxContent h4 {
  font-weight: 400;
  margin-bottom: 0.8rem;
}

.BoxContent a {
  color: #213c53;
}

.BoxContent button {
  background: none;
  border: none;
}

.AddressBox {
  border-bottom: 1px solid #ddd;
  margin-bottom: 2rem;
}

.Errors {
  padding: 2rem;
  color: #D8000C;
  background-color: #FFD2D2;
}

.Hidden {
  display: none;
}

.Visible {
  display: block;
}
