.TopHeader {
  font-size: 1.5rem;
  color: #999;
  background-color: #f8f8f8;
}

.TopHeader ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
}

.TopHeader li {
  padding: 2rem;
}

.TopHeader .Item {
  color: #999;
  text-decoration: none;
  background: none;
  border: 0;
  transition: all 0.5s ease;
}

.TopHeader .Item:hover {
  color: #000;
  text-decoration: none;
}

.TopHeader .Dropdown {
  position: relative;
}

.Dropdown .DropdownContentWrapper {
  position: absolute;
  right: 0%;
  transform: translateY(-300%);
  z-index: 3;
  transition: all 0.5s ease-out;
}

.Dropdown .DropdownContent {
  display: block;
  text-align: center;
  width: max-content;
  background-color: #FFF;
  opacity: 0;
  transition: all 0.5s ease;
}

.Dropdown .DropdownContent li .Item {
  color: #000;
  padding: 0 2.5rem;
  transition: all 0.5s ease;
}

.Dropdown:hover .DropdownContentWrapper {
  transform: translateY(0);
}

.Dropdown:hover .BtnDropdown {
  color: #000;
}

.Dropdown:hover .DropdownContent {
  opacity: 1;
}

.Dropdown .DropdownContent li:hover .Item {
  color: #999;
}
