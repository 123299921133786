.ProductCard {
  height: 100%;
}

.CardContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ProductCard ul {
  list-style-type: none;
}

.ProductCard .CardCover {
  position: relative;
  height: 100%;
}

.CardImg {
  text-align: center;
}

.CardImg img {
  width: 100%
}
.modalbtt{
  border: none;
}
.palyVideo{
  padding: 11px 21px 8px 24px;
  font-size: 43px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-radius: 57px;
  color: #fff;
  opacity: 0;
  cursor: pointer;
  transition: opacity 1s;
}
.ProductCard:hover .palyVideo {
  opacity: 0.7;
}
.freeprice{
  color: #cc4444;
}
.freeTag{
  top: 0;
  left: 0;
  position: absolute;
  opacity: 1;
}
.freeTag img{
  width: 100px;
  transform: rotate(-20deg);
}
.Actions {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 3%;
  bottom: 3%;
  opacity: 0;
  transition: opacity 1s;
}

.Action {
  color: #6d7e87;
  /* color: #FFF; */
  cursor: pointer;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.ProductCard:hover .Actions {
  opacity: 1;
}

.CardInfo {
  color: #000;
  padding: 1rem 0;
}

.CardInfo a {
  cursor: pointer;
  text-decoration: none;
}

.CardInfo h2 {
  color: #777;
}

.DelPrice {
  color: #aaa;
}

.ItemColor {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border: 2px solid #f7f7f8;
}

.ItemSize {
  font-size: 1.5rem;
  background-color: #FFF;
  padding: 0.2rem 0.7rem;
  border: 1px solid #f7f7f7;
  border-radius: 15%;
  cursor: pointer;
}

.ItemSize a {
  color: #000;
}

.ItemSize.Selected {
  background-color: #6d7e87;
}

.ItemSize.Selected a {
  color: #FFF;
}
