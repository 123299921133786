.Button {
  color: #FFF;
  background-color: #6d7e87;
  font-size: large;
  text-transform: uppercase;
  padding: 1rem 2rem;
  border: 1px solid #6d7e87;
  cursor: pointer;
  z-index: 1;
}

.Button:hover {
  text-decoration: none;
}

.Animated {
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
}

.Animated::before {
  content: "";
  background-color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scale(0);
  transform-origin: top right;
  transition: all 0.3s;
}

.Animated:hover {
  color: #000
}

.Animated:hover::before {
  transform: scale(1);
}

/* the is the used part not the above one */
.Btn:disabled {
  cursor: not-allowed;
}

.Btn {
  color: #FFF;
  background-color: #6d7e87;
  font-size: large;
  text-transform: uppercase;
  padding: 1rem 2rem;
  border: 1px solid #6d7e87;
  cursor: pointer;
  z-index: 1;
  transition: all 0.5s;
}

.Btn:hover:not([disabled]) {
  text-decoration: none;
  color: #000;
  background-color: #FFF;
}
