.Dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.DropdownContent {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Dropdown:hover .DropdownContent {
  display: block;
}

.NavItem {
  display: block;
  color: black;
  text-decoration: none;
  width: 100%;
  padding: 1rem;
  font-size: 1.7rem;
  font-weight: 600;
  background-color: transparent;
  text-transform: capitalize;
  cursor: pointer;
  transition: color 0.3s;
}

.NavItem:hover,
.NavItem .active {
  color: #6d7e87;
  text-decoration: none;
}
@media (max-width: 991px) {
  .Dropdown {
    display: block;
  }
}
