/* Forms */
.AdminForm {
  padding: 3rem;
  border: 1px solid #ddd;
}

.AdminForm .CoverCard {
  width: 100%;
  height: 30rem;
}

.AdminForm .CoverCard img {
  height: 100%;
  width: auto;
  max-width: 100%;
}

.White {
  background-color: #FFF;
}

/**************************************/
/* Tables */
.AdminList {
  font-size: 2rem;
}

.PaperContent {
  padding: 2rem;
}

.Toolbar {
  justify-content: space-between;
}

.SearchInput {
  width: 40%;
}

.ItemImage {
  max-height: 10rem;
}

.Icon {
  margin: 0 0.5rem;
}
