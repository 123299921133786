.SideMenu {
  position: sticky;
  top: -1px;
  font-size: 2rem;
  height: 100vh;
  background-color: #bdd0d8;
}

.SideMenu ul {
  list-style-type: none;
}

.SideMenu li {
  cursor: pointer;
  border: 1rem solid #bdd0d8;
}

.SideMenu a {
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

.SideMenu a:hover {
  text-decoration: none;
}

.Link,
.Link a {
  color: #bdd0d8;
}

.Link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e282c;
}

.SubLink {
  background-color: #2c3b41;
}

.Link a, .Arrow {
  padding: 1rem 2rem;
  border-style: solid;
  border-color: #1e282c;
}

.Link a {
  border-width: 1px 0 1px 1px;
}

.Arrow {
  width: 20%;
  text-align: center;
  border-width: 1px 1px 1px 0;
}

.Link:hover a,
.Link:hover .Arrow,
.Link .Active,
.Active+.Arrow {
  color: #FFF;
  border-color: #FFF;
}

.SubLinks {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.SubLinks.Shown {
  max-height: 100px;
}

.ArrowIcon {
  transition: transform 0.3s;
}

.RotatedArrow {
  transform: rotateZ(-90deg);
}
