.LoaderBackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
}

.Loader {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
