.HeaderSlider {
  position: relative;
  overflow: hidden;
}

.Slider {
  position: relative;
  height: 20rem;
}

@media (min-width: 992px) {
  .Slider {
    height: 477px;
  }
}

.Slider img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  /* width: auto; */
  opacity: 0;
  transition: opacity 1s;
}

.Arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  background-color: #EEEEEE88;
  width: 3rem;
  height: 3rem;
  padding: 2rem;
  border-radius: 100%;
  text-decoration: none;
  cursor: pointer;
}

.Arrow .Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Next {
  right: 5%;
}

.Prev {
  left: 5%;
}

.AutoSlider__content {
  /*animation-name: slide;
    animation-duration:10s;
    animation-iteration-count: infinite;
    animation-timing-function:ease-in-out; */
  animation: slide 10s ease-in-out infinite;
  /*animation-direction: alternate-reverse; */
}

.AutoSlider__content:hover {
  animation-play-state: paused;
}

@keyframes slide {
  30% {
    transform: translateX(0);
  }

  33% {
    transform: translateX(-100%);
  }

  63% {
    transform: translateX(-100%);
  }

  66% {
    transform: translateX(-200%);
  }

  96% {
    transform: translateX(-200%);
  }
}
