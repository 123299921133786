.NavItems {
  justify-content: space-around;
  flex-direction: column;
  padding: 1rem;
}

.Back {
  padding: 2rem 3rem;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}

.Arrow {
  padding-left: 1rem;
}

@media (min-width: 992px) {
  .NavItems {
    flex-direction: row;
    display: flex;
  }

  .Back {
    display: none;
  }
}
