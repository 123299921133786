.OrderCard {
  /* margin-right: 10rem;
  margin-left: 10rem; */
  margin-bottom: 1rem;
}

.OrderDetails,
.OrderProducts {
  padding: 1.5rem 3rem;
  border: 1px solid #ddd;
}

.OrderDetails {
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  border-radius: 4px 4px 0 0;
}

.Detail {
  margin-right: 2rem;
}

.Item {
  padding-left: 7rem;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.ItemImg {
  width: 7rem;
  margin-left: -7rem;
}

.DelPrice {
  color: #aaa;
}

.DownloadLink{
  position: absolute;
  right: 0;
  padding: 8px 20px;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ccc;
}
