.SubCategoryCard {
  padding: 1rem;
  text-align: center;
}

.CardContent {
  display: inline-block;
}

.CardCover {
  height: 32rem;
  display: inline-block;
}

.CardCover a,
.CardCover img {
  display: inline-block;
  height: 100%;
}

.Description h3 {
  font-size: large;
  font-weight: bold;
  text-transform: uppercase;
  margin: 1rem 0 2rem 0;
}

.Description a {
  color: #000;
  background-color: transparent;
  font-size: large;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  padding: 1rem;
  border: 1px solid #6d7e87;
  z-index: 1;
}

.Description a:hover {
  text-decoration: none;
}

.Animated {
  position: relative;
  transition: color 0.5s;
  overflow: hidden;
}

.Animated::before {
  content: "";
  background: #6d7e87;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scaleX(0);
  /* transform: scaleX(0) rotate(45deg); */
  transform-origin: center;
  transition: all 0.5s;
}

.Animated:hover {
  color: #FFF
}

.Animated:hover::before {
  transform: scaleX(1);
}
