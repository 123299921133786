.modal {
  font-size: 2rem;
  z-index: 1301;
}

.modal .modal-title,
.modal .close {
  font-size: 2rem;
}

.modal button {
  font-size: 1.5rem;
}
