.SideCartProduct {
  color: #666;
}

.SideCartProduct a,
.SideCartProduct a:hover {
  color: #333;
  text-decoration: none;
  display: inline-block;
}

.SideCartProduct .Trash svg {
  font-size: 3rem;
  color: #999;
  cursor: pointer;
}
