.Product {
  font-size: 1rem;
  padding: 2rem;
  background-color: #FFF;
}

.ImgSelector {
  width: 25%;
  padding: 0 1rem;
  margin: 0 1rem;
  border: 1px solid #ddd;
}

.Product ul {
  list-style-type: none;
}

.Product li {
  padding: 1rem 0;
  margin: 1rem 0;
  border-bottom: 1px dashed #ddd;
}

.Product h2 {
  font-size: 3rem;
  font-weight: 500;
}

.Product h3 {
  font-weight: 400;
  font-size: 2rem;
}

.DelPrice {
  color: #aaa;
}

.ItemColor {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  border: 2px solid #f7f7f8;
  box-shadow: 0 2px 5px #777;
}

.ItemSize {
  font-size: 1.5rem;
  background-color: #FFF;
  padding: 0.5rem 1.2rem;
  margin-right: 1.5rem;
  border: 1px solid #f7f7f7;
  border-radius: 15%;
  cursor: pointer;
}

.ItemSize a {
  color: #000;
}

.ItemSize.Selected {
  background-color: #6d7e87;
}

.ItemSize.Selected a {
  color: #FFF;
}

.QuantityBtn {
  color: #666;
  font-size: 2rem;
  padding: 0.5rem 1.2rem;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.InputCounter {
  height: auto;
  width: 20%;
  text-align: center;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.Btn {
  color: #FFF;
  background-color: #6d7e87;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1rem 2rem;
  border: 1px solid #6d7e87;
  cursor: pointer;
  z-index: 1;
  transition: all 0.5s;
}

.Btn:hover {
  text-decoration: none;
  color: #000;
  background-color: #FFF;
}

.Icon {
  color: #6d7e87;
}
