.PanelLink {
  color: black;
  text-decoration: none;
  transition: all 0.5s;
}

.PanelLink:hover {
  color: #6d7e87;
  padding-left: 2.5rem;
  text-decoration: none;
}

.Active {
  color: #6d7e87;
  padding-left: 2.5rem;
  text-decoration: none;
}

.Arrow {
  padding-right: 1rem;
}
