.SectionTitle {
  text-align: center;
  padding: 1rem;
}

.SectionTitle h2 {
  color: #000;
  font-size: 4rem;
  font-weight: 700;
  text-transform: none;
  text-align: center;
  text-decoration: none;
  display: inline;
  padding: 0 4rem;
  background-image: linear-gradient(0, transparent 0%, #E2E2E2 0);
  background-image: -webkit-linear-gradient(0, transparent 0%, #E2E2E2 0);
  background-position: center center;
  background-size: 100% 25%;
  background-repeat: no-repeat;
}

.SectionTitle p {
  font-size: 2.5rem;
  font-weight: 300;
}
