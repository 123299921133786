.Auth {
  font-size: 1.5rem;
}

.Box .BoxTitle {
  font-size: 2.25rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.Box .BoxContent {
  padding: 2rem;
  border: 1px solid #ddd;
  height: 100%;
}

.Errors {
  padding: 2rem;
  color: #D8000C;
  background-color: #FFD2D2;
}

.Hidden {
  display: none;
}

.Visible {
  display: block;
}
