.SideCart {
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  color: #333;
  background-color: #FFF;
  padding: 1rem;
  z-index: 10;
  transition: transform 0.3s ease-out;
}

.SideCart ul {
  list-style-type: none;
}

.SideCart li {
  border-bottom: 1px solid #efefef;
  padding: 1rem 0;
  margin: 0.5rem 0;
  width: 100%;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(100%);
}

@media (min-width: 576px) {
  .SideCart {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .SideCart {
    width: 30%;
  }
}

.Back {
  padding: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}

.Arrow {
  padding-left: 1rem;
}

.CartContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Products {
  height: calc(100vh - 28.5rem);
  overflow-y: auto;
}

.Products::-webkit-scrollbar {
  width: 5px;
}

.Products::-webkit-scrollbar-thumb {
  background-color: #888;
}

.Products::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.Buttons button,
.Buttons a {
  background-color: #FFF;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #333;
  border-radius: 0;
}

.Buttons .Checkout {
  margin-top: 1.5rem;
  color: #FFF;
  background-color: #333;
}
