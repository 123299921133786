.Footer {
  font-size: 2rem;
  color: #6d7e87;
  background-color: #F8F8F8;
  /* margin-top: 2rem; */
}

.Footer ul {
  list-style-type: none;
}

.Footer a {
  color: #6d7e87;
  transition: color 0.5s;
  text-decoration: none;
}

.Footer a:hover {
  color: #000;
  text-decoration: none;
}

.Footer h2 {
  color: #000;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.About {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SocialMedia li {
  margin-right: 5rem;
}

.SocialMedia a {
  color: #000;
}

.SocialMedia a:hover {
  color: #6d7e87;
}

.Links li {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.Copyrights {
  color: #000;
  background-color: #FFF;
  font-size: 1.5rem;
  padding: 2rem 5rem;
}
