.Navbar {
  position: sticky;
  top: -1px;
  padding: 2rem 0rem;
  background-color: #FFF;
  z-index: 2;
}

.Tools {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Icon {
  position: relative;
  color: #7a8b94;
  cursor: pointer;
  padding: 0 1rem;
}

.CartCount {
  position: absolute;
  top: -0.7rem;
  right: 0.3rem;
  font-size: 1.2rem;
  text-align: center;
  width: 2rem;
  height: 2rem;
  color: #FFF;
  background-color: #000;
  border: 1px solid #FFF;
  padding: 0.1rem 0rem;
  border-radius: 100%;
}

@media (max-width: 991.98px) {
  .NavItems {
    display: none;
  }

  .ToggleSideDrawer {
    display: block;
  }
}

@media (min-width: 992px) {
  .NavItems {
    display: block;
  }

  .ToggleSideDrawer {
    display: none;
  }
}
