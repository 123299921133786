.SideDrawer {
  position: fixed;
  width: 45rem;
  max-width: 70%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #FFF;
  padding: 2rem 1rem;
  z-index: 4;
  transition: transform 0.3s ease-out;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(100%);
}

@media (min-width: 992px) {
  .SideDrawer {
    display: none;
  }
}
