.Checkout {
  color: #777;
  padding: 2rem;
}

.Checkout label {
  font-size: 1.5rem;
}

.Box {
  /* position: relative; */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 4rem;
}

.CartBox {
  height: 100%;
}

.Box ul {
  list-style-type: none;
}

.Box li {
  border-bottom: 1px solid #efefef;
  padding: 1rem 0;
  margin: 0.5rem 0;
  width: 100%;
}

.BoxTitle {
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
}

.BoxTitle h5,
.BoxTitle h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 3rem;
  letter-spacing: .1rem;
}

.BoxTitle h5 {
  color: #222;
}

.Btn {
  color: #FFF;
  background-color: #6d7e87;
  font-size: large;
  text-transform: uppercase;
  padding: 1rem 2rem;
  border: 1px solid #6d7e87;
  cursor: pointer;
  z-index: 1;
  transition: all 0.5s;
}

.Btn:hover {
  text-decoration: none;
  color: #000;
  background-color: #FFF;
}

.FullWidth {
  width: 100%;
}

.Checkout h3 a {
  color: #777;
  text-decoration: none;
}
.centeredtext{
  text-align: center;
}
.MarginTopXLarg{
  margin-top: 20px;
}
.TanksMessageTxt{
    font-size: 18px;
    color: #559aab;
}