.AreaBar {
  color: #000;
  padding: 4rem 6rem;
  background-color: #F8F8f8;
  display: flex;
  justify-content: space-between;
}

.AreaBar h2 {
  text-transform: capitalize;
}

.AreaBar h3 {
  text-transform: uppercase;
}

.AreaBar a, .AreaBar a:hover {
  color: #555;
  text-decoration: none;
}
