.ProfileWrapper {
  padding: 3rem 1rem;
  width: 100%;
}

/* Forms */
.VendorForm {
  padding: 3rem;
  /* border: 1px solid #ddd; */
}

.VendorForm .CoverCard {
  width: 100%;
  height: 30rem;
}

.VendorForm .CoverCard img {
  height: 100%;
  width: auto;
  max-width: 100%;
}

/**************************************/
/* Tables */
.VendorList {
  font-size: 2rem;
}

.PaperContent {
  padding: 2rem;
}

.Toolbar {
  justify-content: space-between;
}

.SearchInput {
  width: 40%;
}

.ItemImage {
  max-height: 10rem;
}

.Icon {
  margin: 0 0.5rem;
}
